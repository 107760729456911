import { DOCUMENT } from '@angular/common';
import {
	Compiler,
	Component,
	Inject,
	Injector,
	LOCALE_ID,
	NgModuleFactory,
	OnInit,
	Renderer2,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { AppDirectionalityService } from '@ngx-common/services/app-directionality.service';
import { AuthService } from '@ngx-common/services/auth.service';
import { EnvironmentService } from '@ngx-common/services/enviroments.service';
import { LanguageService } from '@ngx-common/services/language.service';
import { UrlLocationService } from '@ngx-common/services/url-location.service';
import { setAppInjector } from '@ngx-common/utils/angular-injector.utils';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
@Component({
	selector: 'app',
	templateUrl: 'app.component.html',
	styles: [
		`
			.navigation-progress-bar {
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 1100;
			}
		`,
	],
})
export class AppComponent implements OnInit {
	@ViewChild('flashAlertsContainer', { read: ViewContainerRef })
	flashAlertsContainer: ViewContainerRef;
	pageLoading = false;
	private _isReloadMessageShown: boolean = false;
	constructor(
		public injector: Injector,
		private _router: Router,
		private _ngxTranslateSvc: TranslateService,
		private _clsAlertsSvc: ClsAlertsService,
		private _authSvc: AuthService,
		private _compiler: Compiler,
		private _urlLocationSvc: UrlLocationService,
		private readonly dir: AppDirectionalityService,
		private _renderer: Renderer2,
		private _envSvc: EnvironmentService,
		@Inject(DOCUMENT) private _document: Document,
		@Inject(LOCALE_ID) private _locale: string,
		private _languageSvc: LanguageService
	) {
		setAppInjector(injector);
	}

	async ngOnInit() {
		// #region translation setup
		this.setupLanguage();

		this._ngxTranslateSvc.setDefaultLang('en');
		this._ngxTranslateSvc.use(this._locale);

		if (this.dir.value === 'rtl') {
			this._renderer.addClass(this._document.body, `copyleaks-rtl`);
		}

		this._setupRoutingHandlers();
		setTimeout(() => {
			this._authSvc.checkIfUserAlreadyLoggedin();
		}, 1000);

		this._handleDynamicModulesLazyLoad();

		// set app height for mobile view
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--app-height', `${vh}px`);
		window.addEventListener('resize', () => {
			// We execute the same script as before
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--app-height', `${vh}px`);
		});
	}

	private _handleDynamicModulesLazyLoad() {
		// flash alerts module
		this._authSvc.isLogedIn$
			.pipe(
				filter(isLogedIn => isLogedIn),
				take(1)
			)
			.subscribe(_ => {
				// using timeout coz module loading should not run on the main thread.
				setTimeout(() => {
					this._loadFlashAlertsModule();
				}, 0);
			});
		// ------------------
	}

	private async _loadFlashAlertsModule() {
		const { FlashAlertsModule } = await import('@ngx-common/components/flash-alerts/flash-alerts.module');
		const { FlashAlertsComponent } = await import('@ngx-common/components/flash-alerts/flash-alerts.component');
		const moduleFactory =
			FlashAlertsModule instanceof NgModuleFactory
				? FlashAlertsModule
				: await this._compiler.compileModuleAsync(FlashAlertsModule);
		const moduleRef = moduleFactory.create(this.injector);
		const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(FlashAlertsComponent);
		this.flashAlertsContainer?.clear();
		this.flashAlertsContainer?.createComponent(componentFactory);
	}

	private async setupLanguage(): Promise<void> {
		await this._languageSvc.setupLanguage();
	}

	private async _setupRoutingHandlers() {
		this._router.events.subscribe(event => {
			switch (true) {
				case event instanceof NavigationStart:
					setTimeout(() => {
						this.pageLoading = true;
					});
					break;
				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError:
					setTimeout(() => {
						this.pageLoading = false;
					});
					if (event instanceof NavigationError && event?.error?.name === 'ChunkLoadError') {
						if (!this._isReloadMessageShown) {
							this._isReloadMessageShown = true;
							this._clsAlertsSvc.showVersionUpdateMessage(() => {
								const _location = this._urlLocationSvc.location;
								setTimeout(() => {
									_location.href = `${_location.origin}${event.url}`;
								}, 1000);
								this._isReloadMessageShown = false;
							});
							// const message = this._snackbarSvc.showVersionUpdateMessage();
							// message
							// 	.onAction()
							// 	.pipe(takeWhile(() => this._isReloadMessageShown))
							// 	.subscribe(() => {
							// 		const _location = this._urlLocationSvc.location;
							// 		setTimeout(() => {
							// 			_location.href = `${_location.origin}${event.url}`;
							// 		}, 1000);
							// 	});
							// message
							// 	.afterDismissed()
							// 	.pipe(takeWhile(() => this._isReloadMessageShown))
							// 	.subscribe(() => {
							// 		this._isReloadMessageShown = false;
							// 	});
						}
					}
					break;
				default:
					break;
			}
		});
	}
}
